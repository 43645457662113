@use '../../base_css/abstracts/abstracts' as *;

.gamification-alerts-container-flashy {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
}

.gamification-alert {
  @include fontSize(14);
  position: relative;
  width: 100%;
  padding: 15px 15px 15px 69px;
  font-weight: var(--sans-semi-bold);
  line-height: 18px;

  &::before {
    left: 0.8rem;
  }

  a:not([class]) {
    @include fontSize(14);
  }

  .alert-title {
    @include fontSansRegular;
    @include fontSize(14);
    line-height: 19px;
  }

  .close {
    display: none;
  }

  &.flashy {
    width: 335px;
    padding: 12px 26px 12px 69px;
    margin-top: 10px;
    transition: transform 0.6s;

    &::before {
      left: 12px;
    }

    .close {
      position: absolute;
      top: 12px;
      right: 12px;
      display: block;
      color: var(--c_primary);
      font-size: 10px;
    }
  }

  &.hidden {
    display: none;
  }
}

.gamification-alerts-container {
  position: absolute;
  top: 56px;
  left: 0;
  z-index: 99;
  transform: translateX(-222px);
  display: none;
  width: 397px;
  min-height: 60px;
  box-shadow: 10px 10px 24px rgba(0, 0, 0, 10%);
  background-color: var(--c_white);
  text-align: left;

  &::before {
    position: absolute;
    top: -12px;
    left: 222px;
    content: '';
    width: 35px;
    height: 12px;
    background: url('../../../images/icons/fastbooking_select_arrow.svg') no-repeat;
  }

  & > .close {
    display: none;
  }

  .gamification-alert:not(:last-child) {
    border-bottom: 1px solid var(--c_grey_200);
  }
}

.alert-level {
  @include icon(ico_smile, before, 'xl') {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    color: var(--c_carrot);
  }

  .alert-title {
    color: var(--c_carrot);
    text-transform: uppercase;
  }

  &.flashy {
    border: 1px solid var(--c_carrot);
    border-radius: 4px;
    background-color: var(--c_carrot_secondary);
  }
}

.alert-points,
.General {
  @include icon(star_iberostar, before, 'xl') {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--c_grey_200);
  }

  .alert-title {
    color: var(--c_blue_700);
    text-transform: uppercase;

    .points-num {
      padding-right: 6px;

      &::after {
        position: initial;
        margin-bottom: 2px;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  &.flashy {
    border: 1px solid var(--c_blue_grey);
    border-radius: 4px;
    background-color: var(--c_white);
  }
}

.alert-action {
  @include icon(webcam-2, before, 'xl') {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    color: var(--c_primary);
  }

  .alert-title {
    color: var(--c_dark_grey);
    text-transform: uppercase;
  }

  &.flashy {
    border: 1px solid var(--c_primary);
    border-radius: 4px;
    background-color: var(--c_blue_green_100);
  }
}

.alert-promocode {
  @include icon(percentage, before) {
    @include fontSize(28);
    position: absolute;
    top: 16px;
    left: 15px;
    color: var(--c_primary);
  }

  .alert-title {
    color: var(--c_dark_grey);
  }

  &.flashy {
    border: 1px solid var(--c_primary);
    border-radius: 4px;
    background-color: var(--c_blue_green_100);
  }
}

.flash {
  &.fixed {
    position: fixed;
    top: 0;
    z-index: 9999;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    text-align: center;
    transition: transform 0.6s;

    &.active {
      display: block;
    }

    .alert {
      @include fontSize(16);
      width: 100%;
      padding: 18px 20px 15px;
      color: var(--c_black);
      line-height: 26px;

      &::before {
        margin-top: -4px;
        margin-right: 7px;
        font-size: 32px;
      }

      &.alert-success {
        @include icon(alert-check, before) {
          color: var(--c_green);
        }
        border: 1px solid var(--c_green);
        background: var(--c_green_secondary);
      }

      &.alert-warning,
      &.alert-no_activated {
        @include icon(alert-warning, before) {
          color: var(--c_carrot);
        }
        border: 1px solid var(--c_carrot);
        background: var(--c_carrot_secondary);
      }

      &.alert-danger {
        @include icon(alert-error, before) {
          color: var(--c_danger);
        }
        border: 1px solid var(--c_danger);
        background: var(--c_danger_secondary);
      }

      .close {
        display: none;
      }
    }
  }
}

.alert:not(.fixed) {
  @include fontSize(12);
  @include fontSansSemiBold;
  position: relative;
  display: inline-block;
  min-width: 282px;
  padding: 10px 48px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 9%);
  background: var(--c_white);
  line-height: 18px;

  &.hidden {
    display: none;
  }

  &.negative {
    box-shadow: none;
  }

  &.m0 {
    margin: 0;
  }

  &.fullWidth {
    width: 100%;
  }

  &.fluid {
    min-width: auto;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    content: '';
    width: 16px;
    height: 16px;
    background: url('../../../images/alerts/alert-info.svg') no-repeat;
    background-size: 15px;
  }

  .close {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: var(--c_primary);
    font-size: 12px;
  }

  &.alert-success {
    background: var(--c_green_secondary);

    &::before {
      background: url('../../../images/alerts/check.svg') no-repeat;
    }
  }

  &.alert-info,
  &.alert-notice {
    background: var(--c_grey_100);
  }

  &.alert-warning,
  &.alert-no_activated {
    background: var(--c_ambar_secondary);

    &::before {
      height: 15px;
      background: url('../../../images/alerts/warning.svg') no-repeat;
    }
  }

  &.alert-danger {
    background: var(--c_danger_secondary);

    &::before {
      background: url('../../../images/alerts/alert.svg') no-repeat;
    }
  }
}

@include desktopStyles {
  .gamification-alerts-container {
    .gamification-alert {
      &:hover {
        cursor: pointer;
        background-color: var(--c_blue_green_100);
      }
    }
  }
}

@include tabletStyles {
  .gamification-alerts-container {
    transform: translateX(-320px);

    &::before {
      display: none;
    }
  }

  .peakBallon {
    position: absolute;
    top: -11px;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border-right: 17px solid transparent;
    border-bottom: 12px solid var(--c_white);
    border-left: 17px solid transparent;
  }
}

@include mobileStyles {
  .gamification-alerts-container-flashy {
    right: 20px;
  }

  .gamification-alerts-container {
    position: fixed;
    top: 80px;
    z-index: 99;
    transform: translate(0);
    display: none;
    width: 100vw;
    padding-bottom: 52px;
    box-shadow: 0 -2px 19px rgba(117, 124, 133, 23%);
    background-color: var(--c_white);
    text-align: left;

    &.accesible {
      max-height: 100vh;
      padding-bottom: 150px;
      overflow: scroll;
    }

    &::before {
      left: 240px;
    }

    & > .close {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      color: var(--c_primary);
      font-size: 10px;
    }

    & > a:not(.close) {
      position: absolute;
      left: 16px;
      bottom: 16px;
    }

    &.accesible {
      & > a:not(.close) {
        position: relative;
        top: 10px;
        left: 0;
        bottom: auto;
      }
    }

    .gamification-alert {
      @include fontSize(12);
      padding: 15px 28px 15px 59px;
      border-bottom: 1px solid var(--c_grey_200);

      p {
        display: inline;
      }

      a:not([class]) {
        @include fontSize(12);
      }
    }
  }

  .gamification-alert {
    &.flashy {
      width: 88vw;
    }
  }

  .simple_form[data-form-name='reservations'] {
    margin-top: 5em;
  }
}

.message-alert {
  position: relative;
  padding: 24px 24px 24px 48px;
  border: 1px solid var(--c_primary);
  background-color: var(--c_grey_100);
  font-size: 14px;

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    color: var(--c_primary);

    .s-close-fat {
      pointer-events: none;
    }
  }

  &__title {
    display: table;
    margin-left: -24px;
    font-weight: bold;
    line-height: 1.3em;
    letter-spacing: 0.01em;

    &-icon::after {
      font-weight: bold;
      font-size: 18px;
    }

    &-text {
      display: table-cell;
      padding-left: 10px;
    }
  }

  &__message {
    font-weight: normal;
    line-height: 1.3em;
    letter-spacing: 0.01em;

    p {
      margin-bottom: 1em;
    }
  }

  &__link {
    font-weight: bold;
  }
}
