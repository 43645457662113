@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.user-links {
  > span {
    display: block;
    text-align: right;

    a {
      text-decoration: underline;

      &.edit {
        @include fontSize(14);

        @include icon(person, after, 'm') {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        position: relative;
        padding-left: 30px;
        color: var(--c_primary);
        font-weight: var(--sans-bold);
        font-family: var(--font-sans);
        line-height: 20px;
        text-decoration: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
