@use '../../base_css/abstracts/abstracts' as *;

.comp-card-select {
  display: flex;
  flex: 0 0 26%;

  .option-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 14px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 8%);
  }

  .option-outer {
    position: relative;
    width: 100%;
    padding: 28px 0 18px 16px;
    border: 1px solid transparent;
    border-bottom: 0 !important;
    box-shadow: none;
    background-color: transparent;
  }

  .selector-title {
    @include icon(hotel-bell-ringing, before, 'xxl') {
      display: block;
      margin-bottom: 10px;
      color: var(--c_grey_200);
    }
    margin-bottom: 10px;
    text-align: center;
  }

  .below {
    padding-top: 8px;
    margin-top: auto;
  }

  .fz-row {
    margin-bottom: 14px;
  }

  .secondary-btn {
    width: 100%;
    text-align: center;
  }

  .formz {
    select {
      padding: 0 40px 10px 11px;
      background: transparent url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="8px" viewBox="0 0 24 8" style="enable-background:new 0 0 24 8;" xml:space="preserve"><path fill="#3AA597" d="M1.8,0.8c-0.4-0.4-1-0.4-1.4-0.1C0,1.1,0,1.7,0.4,2l5,5.2c0.4,0.4,1,0.4,1.4,0l4.9-5c0.4-0.4,0.3-1-0.1-1.3 c-0.4-0.3-1-0.3-1.4,0.1L6.1,5.2L1.8,0.8z"/></svg>') right 8px no-repeat;
    }
  }
}
