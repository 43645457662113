@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonTabletMobile {
  .main-header {
    .main-header-content {
      &--partner {
        width: calc(100% - 350px - 5px - 20px - 130px - 40px);
      }
    }
  }
}

.partners-logo {
  position: relative;
}
