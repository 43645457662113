@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.formz .password-row {
  .validation-success {
    display: flex;
    padding: 12px;
    margin-top: 20px;
    background-color: var(--c_green_secondary);

    .text-success {
      @include fontSize(11);

      @include icon(check, before) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23px;
        height: 22px;
        padding-top: 2px;
        padding-left: 2px;
        margin-right: 12px;
        border-radius: 100px;
        background-color: var(--c_green);
        color: var(--c_white);
      }
      display: flex;
      color: #000;
    }

    &.hidden {
      display: none;
    }
  }

  .validation-errors {
    position: relative;
    display: flex;
    flex-direction: column;
    clear: both;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid var(--c_grey_400);
    background-color: var(--c_grey_100);
    transition: order 1s ease-out;

    &.hidden {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: column;
      transition: order 1s ease-out;
    }

    > p {
      @include fontSansSemiBold;
      @include fontSize(12);
      margin-bottom: 16px;
      line-height: 18px;
    }

    li {
      @include fontSansSemiBold;
      @include fontSize(12);

      @include icon(close, 'before', 'xs') {
        margin-right: 10px;
        color: red;
      }

      @for $i from 7 to 0 {
        &:nth-child(#{$i}) {
          order: #{$i};
        }
      }
      order: 0;
      margin-bottom: 8px;
      line-height: 18px;
      transition: order 1s ease-out;

      &.validated {
        @include icon(check, 'before') {
          color: green;
        }
        order: 8 !important;
        color: #9DA2A9;
        transition: order 1s ease-out;
      }
    }
  }

  .parsley-errors-list {
    li,
    div {
      &:not(.parsley-required) {
        display: none;
      }
    }
  }
}
