@use '../../base_css/abstracts/abstracts' as *;

.comp-results-table {
  padding: 48px 0;
  background-color: var(--c_white);
}

.available-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px 0 0;
}

table.comp-results {
  position: relative;
  width: 100%;
  border-bottom: 0;
  border-collapse: collapse;
  background-color: var(--c_white);
  color: var(--c_dark_grey);
  table-layout: fixed;

  colgroup {
    display: none;
  }

  .table-header {
    .table-header-element {
      background-color: var(--c_blue_green_100);

      &:first-child {
        color: var(--c_black);
      }
    }
  }

  colgroup {
    col {
      width: 25%;
      background-color: var(--c_background);

      &.highlighted {
        background-color: var(--c_grey_100);
      }
    }
  }

  .element-of-table {
    background-color: var(--c_grey_100);

    &:nth-child(2n) {
      td {
        background-color: var(--c_white);
      }
    }

    &:last-child {
      td {
        border-bottom: 0;
      }
    }

    &.sub-section {
      border-top: 1px solid var(--c_cloud);
      border-bottom: 1px solid var(--c_cloud);
      background-color: var(--c_white);

    }

    &.sub-section-content {
      .line {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  } /* tr */

  &:last-child tr {
    border-bottom: 1px solid var(--c_cloud);
  }

  .table-header-element {
    padding: 10px 12px;
    font-weight: 600;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }

  .title-subsection {
    padding: 12px;
  }

  .row-subsection {
    padding: 8px 12px;
  }

  .title-subsection,
  .row-subsection {
    background-repeat: no-repeat;
    background-position: center center;
    line-height: 24px;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &.ok {
      .s {
        color: var(--c_green);
      }
    }

    &.ko {
      .s {
        color: var(--c_danger);
      }
    }

    &.highlighted {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: -8px;
        bottom: -1px;
        content: '';
        display: block;
        width: 6px;
        height: 100%;
        background: linear-gradient(to right, rgba(157, 207, 246, 0%) 0%, rgba(157, 207, 246, 60%) 100%);
      }

      &::after {
        position: absolute;
        top: 0;
        bottom: -1px;
        right: -8px;
        content: '';
        display: block;
        width: 6px;
        height: 100%;
        background: linear-gradient(to right, rgba(157, 207, 246, 60%) 0%, rgba(157, 207, 246, 0%) 100%);
      }
    }
  }

  &.collapsable {
    .table-header-element {
      cursor: pointer;

      &:first-child {
        @include icon(arrow-down, after, 's') {
          position: absolute;
          top: 43%;
          right: 12px;
          transform: translateY(-25%);
          color: var(--c_primary);
          font-weight: 600;
        }
        position: relative;
      }
    }

    &.collapsed {
      tr + tr {
        display: none;
      }

      .table-header-element {
        &:first-child {
          @include icon(arrow-up, after, 's') {
            position: absolute;
            top: 43%;
            right: 12px;
            transform: translateY(-25%);
            color: var(--c_primary);
            font-weight: 600;
          }
        }
      }
    }
  }

  &.disabled {
    margin: 10px 0 20px;

    th {
      cursor: default !important;
      background-color: var(--c_cloud);

      &:first-child {
        background-image: none;
      }
    }

    tr + tr {
      display: none;
    }
  }
}
