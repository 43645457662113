@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.comp-card-footer {
  position: relative;
  display: flex;
  flex: 0 1 393px;
  flex-direction: column;
  height: 188px;
  padding: 6px;
  overflow: hidden;
  border: 1px solid var(--c_grey_100);
  background-color: var(--c_white);

  &:first-child {
    margin-left: 0;
  }

  .text-header {
    padding-right: 14px;
    margin-bottom: 10px;
  }

  .s.s-close-fat {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 0 12px;
    color: var(--c_primary);
    font-size: 10px;
    line-height: 34px;
    text-align: center;
  }

  .title-link {
    margin-bottom: 7px;
    color: var(--c_black);
  }

  .below {
    margin-top: auto;
  }

  .price-cnt {
    display: block;
    width: 100%;
    padding: 0;
    color: var(--c_black_grey_700);
    font-size: 12px;
    line-height: 16px;
    text-align: right;

    .amount {
      margin-left: 10px;
      font-weight: 700;
      font-size: 32px;
    }

    &.hidden {
      display: none;
    }

    .button-enjoy-from {
      width: 100%;
      padding: 12px 24px;
      margin-bottom: 3px;
    }

    .btn-floating-fastbooking {
      width: 100%;
      padding: 5px 24px;

      .price,
      .coin {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .night-person {
    margin: 0;
    text-transform: capitalize;
  }
}

.e-selected-dates {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.hidden {
    display: none;
  }

  .separator {
    display: inline-block;
    width: 10px;
    height: 1px;
    margin: 0 10px;
    background: var(--c_grey_400);
    vertical-align: middle;
  }

  .date {
    @include fontSize(12);

    @include icon(calendar, after, 's') {
      position: absolute;
      top: -1px;
      left: 0;
      z-index: 1;

    }
    position: relative;
    display: inline-block;
    padding-left: 22px;
    line-height: 18px;
    vertical-align: top;

    &.hidden {
      display: none;
    }
  }
}

@include media($mobile) {
  .e-selected-dates {
    justify-content: flex-end;
    margin-top: 10px;
    text-align: right;

    &.nodates {
      .date {
        @include fontSize(12);

        @include icon(alert-warning, after, 'm') {
          left: -8px;
          color: var(--c_danger);
        }
        color: var(--c_danger);
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0;
      }
    }
  }

  .js-price-switch.price-switch {
    justify-content: flex-end;
  }
}
