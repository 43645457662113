@use 'partners_commons' as *;

@include commonTabletDesktop {
  .main-header,
  // PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
  .reb-header__wrapper {
    .logo {
      &--partner {
        width: 350px;
        max-width: 350px;
        padding: 0 20px;
      }

      #partner-logo, .partners-logo {
        display: flex;
        flex-flow: row nowrap;
        gap: 15px;
        align-items: center;
        justify-content: space-between;
        max-width: 235px;
        padding: 1em;
        background-color: var(--c_white);

        &.reb-version {
          padding: 0;
        }

        &.hidden {
          display: none;
        }

        &__images {
          position: relative;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: space-between;
        }

        .reb-version {
          display: flex;
          flex-flow: row nowrap;

        }

        .partner-logo-item {
          &--iberostar {
            img {
              position: relative;
              width: 140px;

            }
          }

          &--partner {
            width: auto;
            max-width: 150px;
            max-height: 35px;
          }
        }

        .partner-logo-item-menu {
          position: relative;

          img {
            position: relative;
            width: 140px;
            margin-right: 25px;
          }
        }

        &__exit {
          @include icon(ico_exit, before, 'm') {
            transform: scaleX(-1);
            color: var(--c_primary);
            vertical-align: bottom;
          }
        }
      }
    }

    .main-header-content {
      &--partner {
        width: calc(100% - 350px - 5px - 20px);
      }
    }
  }

  .hotel-one-landing-page {
    .main-header {
      .wrapper-main-header {
        &.partner-header {
          justify-content: space-between;
          padding-right: 0;

          .logo--partner {
            padding: 0;
          }

          .logo {
            #partner-logo {
              .partner-logo-item--partner {
                margin-left: 0;
              }
            }
          }
        }
      }

      .partner-header {
        .for-user-nav {
          .user-nav {
            & > ul.top-user-nav {
              & > li.toggle-btn {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
