@use '../../base_css/abstracts/abstracts' as *;

/* ------------------------------------*\
  $BREADCRUMBS
\*------------------------------------ */

.breadcrumbs {
  li {
    display: inline-block;

    a {
      @include fontSize(13);
      position: relative;
      display: inline-block;
      padding-right: 10px;
      color: var(--c_white);
      font-family: var(--font-sans);

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        content: ' / ';
      }
    }

    &:last-child {
      a::after {
        display: none;
      }
    }
  }
}
