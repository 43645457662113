@use 'not_logged_booking_commons' as *;

@include desktopStyles {
  .not-logged-form {
    .fz-row {
      justify-content: space-between;

      .fz-col {
        flex: 0 0 calc(50% - 10px);
        flex-basis: calc(50% - 10px);
        min-width: 280px;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@include tabletStyles {
  .not-logged-form {
    .fz-row {
      justify-content: space-between;

      .fz-col {
        flex: 0 0 calc(50% - 10px);
        flex-basis: calc(50% - 10px);
      }
    }
  }
}
