@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.not-logged-form {
  width: 100%;
  max-width: 730px;

  .fancybox-container & {
    padding: 20px;
  }

  .fz-row {
    display: flex;
  }

  &.reservation-management {
    .toggle-inputs-link {
      display: block;
      margin-bottom: 20px;

      &::before {
        opacity: 0;
      }
    }

    .toggle-inputs {
      display: flex;

      &.hidden {
        display: none;
      }
    }
  }
}
