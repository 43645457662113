@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.fastbooking-new-hotel {
  display: none;
  flex-basis: 100%;
  flex-direction: column;
  align-items: center;

  .icon,
  .ann {
    color: var(--c_blue_700);
  }

  .ann {
    @include fontSansSemiBold;
    @include fontSize(14);
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
  }

  .desc {
    @include fontSize(16);
    margin-top: 0;
    line-height: 22px;
    text-align: center;
  }

  .search-cta {
    width: 100%;
    text-align: center;
  }

  .garantee {
    @include fontSansSemiBold;
    @include fontSize(12);
    color: var(--c_primary);
  }

  &--bgcolor {
    background-color: #EFF8F7;

    .ann {
      @include fontSize(12);
    }

    .desc {
      @include fontSize(14);
    }

    .search-cta {
      margin-bottom: 0;
    }
  }
}

@media screen and (width <= 767px) {
  .fastbooking-new-hotel {
    display: flex;
    gap: 10px;
    padding: 15px;

    &--bgcolor {
      margin-bottom: 20px;
    }
  }
}
