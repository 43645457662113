@use 'user_menu_commons' as *;

@include commonTabletDesktop {
  .user-options-overlay {
    position: absolute;
    left: 0;
    z-index: 20;
    opacity: 0.8;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #013;
    transition: 100ms opacity;

    &.hidden {
      opacity: 0;
      display: none;
    }
  }

  .user-options-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 8px 14px;

    &.hidden {
      display: none;
    }

    .user-options-greetings {
      @include icon(person2, before) {
        @include fontSize(70);
        display: inline-block;
        margin-right: 8px;
        color: var(--c_blue_700);
      }
      display: flex;
      flex: 0 0 auto;
      align-items: center;

      .level {
        color: var(--c_carrot);
        line-height: 16px;
        text-transform: uppercase;

        .level-text {
          font-weight: var(--sans-bold);
        }

        .level-bar {
          display: inline-block;
        }
      }

      .user-options-info {
        display: flex;
        flex-direction: column;
      }

      .greetings-text {
        line-height: 14px;

        .greetings-title {
          @include fontSize(14);
          color: var(--c_blue_700);
          font-weight: var(--sans-semi-bold);
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }
      }

      .logout-area {
        @include fontSize(14);
        margin: 2px 0 0;
        font-family: var(--font-sans);

        .logout-link {
          @include icon(ico_exit, before);
        }
      }

      .greetings-text {
        text-align: left;
      }
    }

    .user-options-menu {
      .user-options {
        display: flex;
        flex: 0 0 auto;
        flex-wrap: wrap;
        max-width: 100%;
      }
    }
  }
}

@include tabletStyles {
  .user-options-wrapper {
    &.wrapper {
      padding: 28px 5.334%;
    }

    .user-options-menu {
      padding-left: 15px;
    }
  }
}
