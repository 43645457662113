@use '../../base_css/abstracts/abstracts' as *;
@use 'comp_card_footer';
@use 'comp_card_select';
@use 'comp_results';

.comp-card {
  display: flex;
  flex: 0 0 25%;

  .s.s-close-fat {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 0 12px;
    color: var(--c_primary);
    font-size: 10px;
    line-height: 34px;
    text-align: center;
  }

  .text-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .stars {
      display: inline-block;
      margin-top: 3px;

      .rating-stars {
        &::before {
          margin-bottom: 5px;
        }
      }
    }

    .fav-cnt {
      flex: 0 0 auto;
      margin-bottom: 8px;
    }
  }

  .title-link {
    @include fontSize(18);
    float: left;
    margin: 0 10px 0 0;
    color: var(--c_black);
    line-height: 26px;
  }

  .badges-list {
    width: 100%;

    .badge {
      display: inline-block;
      margin: 0 2px 7px 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .below {
    padding-top: 8px;
    margin-top: auto;

    .price,
    .coin {
      @include fontSize(16);
      font-weight: 700;
    }
  }

  .features,
  .selected-dates {
    border-right: 1px solid var(--c_grey_200);
    border-left: 1px solid var(--c_grey_200);
  }

  .old-price {
    color: var(--c_danger);
    font-size: 14px;
    line-height: 16px;
    text-align: right;

    span {
      text-decoration: line-through;
    }
  }

  .price-cnt {
    margin-top: 3px;
    text-align: right;
  }

  .night-person {
    margin: 0;
    text-transform: capitalize;
  }

  .btn-primary {
    width: 100%;
    text-align: center;
  }

  .option-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 8px;
    border: 1px solid var(--c_grey_100);
  }

  .option-outer {
    position: relative;
    width: 100%;
    padding: 28px 0 18px 16px;
    border: 1px solid transparent;
    border-bottom: 0 !important;
    box-shadow: none;
    background-color: transparent;

    .title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      visibility: hidden;
      height: 19px;
      padding: 4px 16px;
      margin-bottom: 16px;
      background-color: var(--c_danger);
      color: var(--c_white);
      font-weight: 600;
      font-size: 10px;
      font-family: var(--font-sans);
      line-height: 11px;
      letter-spacing: 0.7px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &.highlighted {
    .option-outer {
      border: 1px solid var(--c_grey_100);
      box-shadow: 0 2px 8px 0 var(--c_cloud);
      background-color: var(--c_white);

      .title {
        visibility: visible;
      }
    }
  }
}

/* When card is inside sticky */
.comp-available-options-sticky {
  .comp-card {
    .asterisk,
    .ratio,
    .location,
    .badges-list,
    .e-selected-dates,
    .night-person {
      display: none;
    }

    .text-header {
      padding-right: 14px;
    }

    .title-link {
      float: left;
      margin-right: 10px;
    }

    .btn-primary {
      padding: 10px 20px;
      font-size: 14px;
      line-height: 16px;
    }

    .below {
      padding-top: 0;
    }

    .price-cnt {
      padding: 0 0 4px;
      text-align: left;

      .amount {
        margin-left: 0;
        font-size: inherit;
      }
    }
  }
}

.comp-card,
.comp-card-footer {
  .price-switch {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 18px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.hidden {
      display: none;
    }
  }
}
